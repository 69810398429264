<template>
    <div class="view msglist-wrap" @click="showApp()">
        <ul class="menu-ul flex-bt" style="padding: 10px 0;">
            <li class="list">
                <div class="icon-box">
                    <i class="hwiconfont icon-sc_full" style="color: #FC5E5A;"></i>
                    <span class="num" v-if="nInfo.zan_and_lover!=0">{{nInfo.zan_and_lover>99?'99+':nInfo.zan_and_lover}}</span>
                </div>
                <p class="name">赞和GET</p>
            </li>
            <li class="list">
                <div class="icon-box" style="background: #E7F0FF;">
                    <i class="hwiconfont icon-wode1" style="color:#3685FB;"></i>
                    <span class="num" v-if="nInfo.attention!=0">{{nInfo.attention>99?'99+':nInfo.attention}}</span>
                </div>
                <p class="name">新增关注</p>
            </li>
            <li class="list">
                <div class="icon-box" style="background: #FEEDE8;">
                    <i class="hwiconfont icon-at" style="color:#fd5621;"></i>
                    <span class="num" v-if="nInfo.aite!=0">{{nInfo.aite>99?'99+':nInfo.aite}}</span>
                </div>
                <p class="name">@我的</p>
            </li>
            <li class="list">
                <div class="icon-box" style="background: #E6FBF2;">
                    <i class="hwiconfont icon-xiaoxi3" style="color:#34D799;"></i>
                    <span class="num" v-if="nInfo.comment!=0">{{nInfo.comment>99?'99+':nInfo.comment}}</span>
                </div>
                <p class="name">评论</p>
            </li>
        </ul>
        <ul class="u-wrap">
            <li class="list-wrap sys-msg" v-if="m.id == 1" v-for="(m, index) in ffList2">
                <div class="list">
                    <div class="tx img-h">
                        <img :src="rImg(m.pic)" :onerror="txImg" alt="">
                    </div>
                    <div class="content">
                        <div class="flex-bt">
                            <div class="name-wrap" style="position: relative;">
                                <span class="name">{{m.name}}</span>
                            </div>
                        </div>
                        <div class="flex-bt msg-box">
                            <p class="msg ellipsis-1">{{m.content}}</p>
                            <span class="yuan" v-if="m.count > 0">{{m.count>99? '99+' : m.count}}</span>
                        </div>
                    </div>
                </div>
            </li>
            <li class="list-wrap" :class="{'top':m.top==1,'slid-left':m.s == 1}" v-for="(m, index) in ffList" :id="m.userid" :key="index">
                <div class="list">
                    <div class="tx img-h">
                        <div v-if="showTx" class="tx-img" :style="'background-image:url('+rImg(m.sumb_headimg)+')'"></div>
                        <img v-if="m.noble_circle && showTx" :src="rImg(m.noble_circle)" class="tx_cover" alt="">
                        <span class="online-tag" :class="{'on':m.is_online==1}"></span>
                    </div>
                    <div class="content">
                        <div class="flex-bt">
                            <div class="name-wrap" style="position: relative;">
                                <span class="name">{{m.name}} <span class="aim">[{{m.entertainment_child_text || m.entertainment_text}}]</span><span class="sys-tag" v-if="m.kefu == 1">官方</span></span>
                                <span class="chat-lab" v-if="m.is_chat_discount">{{m.is_chat_discount}}</span>
                                <img v-if="m.noble_icon " class="new-noble" :src="rImg(m.noble_icon)" :onerror="errImg" alt="">
                            </div>
                            <span class="time">{{m.addtime}}</span>
                        </div>
                        <div class="flex-bt msg-box">
                            <p class="msg ellipsis-1" style="max-width: 55%;;">{{clearHtml(m.content)}}</p>
                            <span class="yuan" v-if="m.count > 0">{{m.count>99? '99+' : m.count}}</span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <el-empty v-if="ffList.length == 0" description="空空如也~"></el-empty>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {},
        data() {
            return {
                nInfo: {},
                ffList2: [],
                ffList: [],
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            let _this = this;
            // 在组件被挂载之后调用。
            let config = this.$y_getKey('config');
            let ws = new WebSocket(config.socket_url);
            ws.onopen = () => {
                // 连接socket 绑定
                ws.send(JSON.stringify({
                    userid: config.socket_prefix + _this.$y_getKey('userid')
                }));
            };
            ws.onmessage = (evt) => {
                let received_msg = evt.data;
                if (received_msg && typeof received_msg == 'string' && received_msg.indexOf('pong') == -1) {
                    let rets = JSON.parse(received_msg);
                    _this.dealMsg(rets);
                }
            };
            this.getMsgList();
        },
        methods: {
            ...mapMutations(['s_setCount']),
            dealMsg(rets) {
                let action = rets.action;
                if (!action) return;
                action = action.toLowerCase();
                switch (action) {
                    case 'api/dymanic/interactioncount':
                        this.nInfo = rets.data;
                        break;
                    case 'home/privatechat/getotherlist':
                        this.ffList = rets.data.result || [];
                        break;
                    case 'home/user/syslist':
                        this.ffList2 = rets.data || [];
                        break;
                    default:
                        break;
                }
                let count = this.nInfo.count || 0;
                for (let i = 0, len = this.ffList2.length; i < len; i++) {
                    if (this.ffList2[i].id == 1) {
                        count += this.ffList2[i].count;
                    }
                }
                this.ffList.forEach(v => {
                    count += v.count;
                });
                this.s_setCount(count);
            },
            async getMsgList() {
                let uid = this.$y_getKey('userid')
                // 请求消息列表
                let ret1 = await this.$y_ajax('Home/Privatechat/getOtherList', {
                    data: JSON.stringify({
                        userid: uid
                    })
                }, 1)
                // 请求系统消息列表
                let ret2 = await this.$y_ajax('Home/User/syslist', {
                    user_id: uid
                }, 1)
                // 请求通知消息列表
                let ret3 = await this.$y_ajax('api/dymanic/interactionCount', '', 1)
            }
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    .menu-ul {
        .list {
            width: 25%;
            text-align: center;
        }

        .icon-box {
            width: 45px;
            height: 45px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 7px;
            background: #FFEDEB;
            position: relative;
        }

        .num {
            position: absolute;
            border-radius: 15px;
            background: #FF5454;
            color: #fff;
            font-size: 10px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0 3px;
            line-height: 15px;
            min-width: 15px;
            box-sizing: border-box;
            text-align: center;
            top: -5px;
            left: calc(100% - 7px);
        }

        .hwiconfont {
            font-size: 25px;
        }

        .name {
            font-size: 12px;
            margin-top: 5px;
        }
    }


    .msglist-wrap {
        .tx {
            width: 45px;
            height: 45px;
            position: relative;
            margin-right: 10px;

            img {
                width: 45px;
                height: 45px;
                border-radius: 50%;
            }
        }


        .u-wrap {
            .list {
                padding: 10px;
                font-size: 12px;
                color: #757575;
                display: flex;
                background: #fff;

                .name {
                    font-size: 15px;
                    color: #333333;
                }

                .yuan {
                    border-radius: 15px;
                    background: #FF5454;
                    color: #fff;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 3px;
                    min-width: 15px;
                    box-sizing: border-box;
                    text-align: center;
                }

                .content {
                    flex: 1;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }

            .top {
                .list {
                    background: #f5f5f5;
                }
            }
        }
    }
</style>